<template>
    <div class="brand-list" v-loading="loading" v-if="isCity">
        <div class="brand-head">
            <h2>
                <img src="@/assets/images/city-left.png" />
                {{ cityDetail.title }}
                <img src="@/assets/images/city-right.png" />
            </h2>
        </div>
        <!-- banner -->
        <div class="banner-box">
            <div class="banner-info">
                <h3>{{ cityDetail.title }}</h3>
                <p>{{ cityDetail.desc }}</p>
            </div>
            <div class="banner">
                <el-carousel height="430px" v-if="bannerList.length > 0">
                    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                        <el-image :src="$img(item.path)" fit="cover" />
                    </el-carousel-item>
                </el-carousel>
                <el-carousel height="430px" v-else>
                    <el-carousel-item v-for="item in adList" :key="item.adv_image">
                        <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!-- 城市分站 -->
        <div class="recommendGoods">
            <div class="module-list-head">
                <h2><span></span>推荐产品</h2>
            </div>
            <div class="module-list-body">
                <div class="card-carousel-wrapper">
                    <div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList"></div>
                    <div class="card-carousel">
                        <div class="card-carousel--overflow-container">
                            <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')' }">
                                <div class="card-carousel--card" v-for="(item, count) in recommendGoodsList" :key="count" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?source_type=' + filters.website_type })">
                                    <div class="goods-wrap">
                                        <div class="img-wrap"><img alt="商品图片" :src="$img(item.goods_image[0], { size: 'mid' })" @error="imageError(index)" /></div>
                                        <h3>

<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
                                            {{ item.goods_name }}
                                        </h3>
                                        <p class="desc">{{ item.introduction }}</p>
                                        <p class="price">
                                            <span class="num">￥{{ item.price }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList"></div>
                </div>
            </div>
        </div>
        <div class="feed-tab-wrapper">
            <ul class="feed-tab">
                <li class="feed-tab__item" :class="filters.category_id === 0 ? 'feed-tab__item--active' : ''" @click="selectedAllCategoryGoods()">
                    <div class="feed-tab__item-title">
                        <img v-if="filters.category_id === 0" src="@/assets/images/list-red.png" style="width:19px;height:16px;margin-right:5px;margin-top:-1px" />
                        <img v-else src="@/assets/images/list.png" style="width:19px;height:16px;margin-right:5px;margin-top:-1px" />
                        <span class="feed-tab__item-title-text">全部</span>
                    </div>
                </li>
                <li class="feed-tab__item" v-for="(item, index) in categoryList" :key="index" :class="filters.category_id === item.category_id ? 'feed-tab__item--active' : ''" @click="selectedCategoryGoods(item)">
                    <div class="feed-tab__item-title">
                        <span class="feed-tab__item-title-text">{{ item.category_name }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="list-wrap">
            <div class="cargo-list" v-if="cargoList.length">
                <div class="goods-info">
                    <div class="item" v-for="(item, index) in cargoList" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?source_type=' + filters.website_type })">
                        <div class="goods-wrap">
                            <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" /></div>
                            <h3>

<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
                                {{ item.goods_name }}
                            </h3>
                            <p class="desc" v-if="item.introduction">{{ item.introduction }}</p>
                            <p class="price">
                                <span class="num">￥{{ item.discount_price }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                </div>
            </div>
            <div class="empty" v-else>
                <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
            </div>
        </div>
        <el-dialog title="切换城市" :visible.sync="showWebsiteCity" width="800px" :before-close="handleCloseCity">
            <div class="websiteCity-box">
                <ul>
                    <li class="items" :class="item.site_id == activeCity ? 'active' : ''" v-for="(item, index) in websiteCityData" :key="index" @click="handleSelectWebsiteCity(item.site_id, item.website_type)">
                        <!--                      <router-link class="list-item-lk" :to="{ path: '/citylist', query: { website_id: item.site_id, website_type: item.website_type} }" target="_self">-->
                        {{ item.title }}
                        <!--                      </router-link>-->
                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
    <div class="error-wrap" v-loading="loading" v-else>
        <!-- <i class="iconfont icon404"></i>
      <h2>抱歉！页面无法访问……</h2> -->
        <div>
            <img src="@/assets/images/index/404.png" />
        </div>
        <p>
            <span>抱歉!你访问的页面正在建设中!&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <router-link to="/" class="go-index">返回首页</router-link>
        </p>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { adList } from "@/api/website"
    import { goodsSkuPage, websiteList } from "@/api/goods/goods"
    import { cityBannerList, websiteDetail, recommendGoodsList } from "@/api/goods/goods"
    import { getChildCategory } from "@/api/goods/goodscategory"
    import Cookies from "js-cookie"

    export default {
        name: "list",
        components: {},
        inject: ["reload"],
        computed: {
            atEndOfList() {
                return this.currentOffset <= this.paginationFactor * -1 * (this.recommendGoodsList.length - this.windowSize)
            },
            atHeadOfList() {
                return this.currentOffset === 0
            },
            ...mapGetters(["defaultGoodsImage"])
        },
        data: () => {
            return {
                isCity: true,
                activeCity: "",
                websiteCityData: [],
                showWebsiteCity: false,
                currentOffset: 0,
                windowSize: 5,
                paginationFactor: 215,
                recommendGoodsList: [],
                categoryList: [],
                total: 0,
                keyword: "",
                currentPage: 1,
                pageSize: 20,
                adList: [],
                cityDetail: {},
                bannerList: [],
                cargoList: [],
                filters: {
                    area_id: 0,
                    // area_level: 2,
                    website_id: 0,
                    site_id: 0,
                    category_id: 0,
                    category_level: 0,
                    website_type: 1,
                    type_id: 0
                },
                loading: true
            }
        },
        created() {
            // 获取分站id和type
            this.getCityQuery()
        },
        mounted() {
            // 获取分站id和type
            this.getCityQuery()
        },
        methods: {
            // 获取分站id和type
            getCityQuery() {
                let website_id = Cookies.get("website_id")
                let website_type = Cookies.get("website_type")

                // 如果没有获取到分站参数，后续不执行
                if (!website_id || !website_type) {
                    this.isCity = false
                    this.loading = false
                    return
                }
                website_id = parseInt(website_id)
                website_type = parseInt(website_type)
                this.activeCity = this.filters.website_id = website_id
                this.filters.website_type = website_type
                this.getAdList() //图片列表
                this.getCityBanner() //图片列表
                this.getCityDetail() //城市详情
                this.getGoodsList() //产品列表
                this.getRecommendGoodsList() //推荐产品
                this.getCategory() //菜单
            },
            moveCarousel(direction) {
                if (direction === 1 && !this.atEndOfList) {
                    this.currentOffset -= this.paginationFactor
                } else if (direction === -1 && !this.atHeadOfList) {
                    this.currentOffset += this.paginationFactor
                }
            },
            handleClearCity() {
                this.filters.area_id = this.cityDetail.city_id
                this.filters.area_level = 2
                this.currentPage = 1
                this.getGoodsList() //产品列表
            },
            getAdList() {
                adList({ keyword: "NS_PC_INDEX" })
                    .then(res => {
                        this.adList = res.data.adv_list
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                        }
                    })
                    .catch(err => err)
            },
            selectedCategoryGoods(item) {
                this.filters.category_id = item.category_id
                this.filters.category_level = item.level
                this.currentPage = 1
                this.getGoodsList() //产品列表
            },
            selectedAllCategoryGoods() {
                this.filters.category_id = 0
                this.filters.category_level = 0
                this.currentPage = 1
                this.getGoodsList() //产品列表
            },
            getCategory() {
                getChildCategory({
                    level: 1,
                    pid: 0
                })
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.categoryList = res.data
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            //获取banner
            getCityBanner() {
                cityBannerList({ site_id: this.filters.website_id })
                    .then(res => {
                        if (res.code == 0) {
                            this.bannerList = res.data
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            },
            //获取详情
            getCityDetail() {
                websiteDetail({ site_id: this.filters.website_id })
                    .then(res => {
                        if (res.code == 0) {
                            this.cityDetail = res.data
                            // 写入cookies：分站详情
                            Cookies.set("cityDetail", this.cityDetail, { expires: this.$cookiesDays })
                            // 写入cookies：分站是否允许跳转到首页
                            Cookies.set("is_back_index", this.cityDetail.is_back_index, { expires: this.$cookiesDays })
                            // 写入cookies：分站城市的地理id，如长沙（430100000000）
                            Cookies.set("site_area_id", this.cityDetail.site_area_id, { expires: this.$cookiesDays })
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            },
            //获取详情
            getRecommendGoodsList() {
                recommendGoodsList({ site_id: this.filters.website_id, website_type: this.filters.website_type })
                    .then(res => {
                        if (res.code == 0) {
                            this.recommendGoodsList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            },
            getGoodsList() {
                const params = {
                    page: this.currentPage,
                    page_size: this.pageSize,
                    keyword: this.keyword,
                    ...this.filters
                }
                goodsSkuPage(params || {})
                    .then(res => {
                        const { count, page_count, list } = res.data
                        this.total = count
                        this.cargoList = list
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            handlePageSizeChange(size) {
                this.pageSize = size
                this.currentPage = 1
                this.getGoodsList()
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getGoodsList()
            },
            imageError(index) {
                this.cargoList[index].sku_image = this.defaultGoodsImage
            },
            handleSelectWebsiteCity(id, type) {
                this.showWebsiteCity = false
                this.$router.push({ path: "/citylist", query: { website_id: id, website_type: type } })
                this.$router.back(-1)
                this.reload()
            },
            // 关闭弹窗事件
            handleCloseCity(done) {
                this.showWebsiteCity = false
                done()
            }
        }
    }
</script>

<style lang="scss">
    .brand-head {
        height: 60px;
        margin: 20px 0px 10px;
        text-align: center;
        position: relative;
        h2 {
            font-size: 36px;
            color: #d81e01;
            img {
                margin: 0px 10px;
            }
        }
        .changeCity {
            position: absolute;
            width: 120px;
            height: 20px;
            right: 0;
            bottom: 0;
            text-align: right;
            cursor: pointer;
        }
        .changeCity:hover {
            color: #d81e01;
        }
    }

    .banner-box {
        width: 1210px;
        background: #fff;
        margin: 0px auto;
        padding: 0;
        height: 430px;
        .banner-info {
            background: url("../../../assets/images/city-bg.png") no-repeat center center;
            width: 210px;
            height: 430px;
            overflow: hidden;
            float: left;
            color: #d81e01;
            h3 {
                height: 48px;
                line-height: 24px;
                font-size: 20px;
                margin: 30px 30px 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            p {
                text-indent: 20px;
                font-size: 13px;
                line-height: 18px;
                margin: 0px 30px 30px;
                text-align: left;
                height: 290px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 16;
                -webkit-box-orient: vertical;
            }
        }
        .banner {
            width: 1000px;
            height: 430px;
            position: relative;
            float: left;
        }
    }

    .areaFilters {
        width: 1210px;
        background: #fff;
        margin: 20px auto;
        padding: 0;
        height: 40px;
        position: relative;
        border: 2px solid #d81e01;
        border-radius: 8px;
        .areaFilters--label {
            float: left;
            margin-right: 20px;
            line-height: 40px;
            text-align: right;
            font-size: 16px;
            color: #666;
            width: 5%;
        }
        .areaFilters--content {
            float: left;
            width: 90%;
            .items {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
                font-size: 16px;
                line-height: 40px;
                color: #999;
            }
            .items:hover,
            .items.active {
                color: #d81e01;
            }
        }
    }
    .websiteCity-box {
        position: relative;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            padding: 0 40px;
            cursor: pointer;
            font-size: 20px;
            line-height: 40px;
            color: #999;
        }
        li:hover,
        li.active {
            color: #d81e01;
        }
    }

    .recommendGoods {
        width: 1210px;
        display: block;
        margin: 0px auto 20px;
        background-color: #fff;
        border-radius: 8px;
        border-radius: 8px;

        .module-list-head {
            padding: 0px;
            margin: 0;

            h2 {
                line-height: 42px;
                color: #333;
                padding: 10px 0px;
                font-size: 22px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;

                span {
                    border: 3px solid $base-color;
                    border-radius: 6px;
                    height: 10px;
                    margin: 0px 10px;
                    display: inline-block;
                }
            }
        }

        .module-list-body {
            padding: 0px 10px;
        }
    }

    .card-carousel-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .card-carousel {
            justify-content: center;
            width: 1075px;
            display: flex;
            position: relative;
            overflow: hidden;
            height: 295px;
            .card-carousel-cards {
                display: flex;
                transition: transform 150ms ease-out;
                transform: translatex(0px);
                .card-carousel--card {
                    background: #fff;
                    cursor: pointer;
                    padding: 0;
                    transition: all 0.2s linear;
                    z-index: 3;
                    margin: 0 10px 20px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    .goods-wrap {
                        width: 195px;
                        padding: 15px 0px;
                        .img-wrap {
                            width: 155px;
                            height: 155px;
                            margin: 0px auto;
                            text-align: center;
                            line-height: 160px;
                            /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                            /*border-radius: 16px;*/
                            overflow: hidden;
                            position: relative;
                            img {
                                border: none;
                                position: absolute;
                                margin-right: -75px;
                                top: 50%;
                                left: 50%;
                                display: block;
                                transform: translate(-50%, -50%);
                            }
                        }
                        h3 {
                            font-size: 15px;
                            line-height: 24px;
                            text-align: center;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin: 10px 10px 5px;
                            height: 24px;
                        }
                        .desc {
                            margin: 0 10px;
                            height: 14px;
                            font-size: 13px;
                            line-height: 14px;
                            color: #b0b0b0;
                            text-align: center;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .price {
                            margin: 0 10px;
                            font-size: 13px;
                            /*text-align: center;*/
                            font-weight: 700;
                            color: $base-color;
                            del {
                                margin-left: 0.5em;
                                color: #b0b0b0;
                            }
                        }
                    }

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }
                }
                .card-carousel--card:first-child {
                    margin-left: 10px;
                }
                .card-carousel--card:last-child {
                    margin-right: 10px;
                }
            }
        }
        .card-carousel--overflow-container {
            overflow: hidden;
            padding: 10px 0px;
        }
        .card-carousel--nav__left,
        .card-carousel--nav__right {
            display: inline-block;
            width: 20px;
            height: 20px;
            padding: 0;
            box-sizing: border-box;
            border-top: 2px solid #d81e01;
            border-right: 2px solid #d81e01;
            cursor: pointer;
            margin: 0 10px;
            transition: transform 150ms linear;
        }
        .card-carousel--nav__left[disabled],
        .card-carousel--nav__right[disabled] {
            opacity: 0.2;
            border-color: black;
        }
        .card-carousel--nav__left {
            transform: rotate(-135deg);
        }
        .card-carousel--nav__left:active {
            transform: rotate(-135deg) scale(0.9);
        }
        .card-carousel--nav__right {
            transform: rotate(45deg);
        }
        .card-carousel--nav__right:active {
            transform: rotate(45deg) scale(0.9);
        }
    }

    .feed-tab-wrapper {
        width: 1170px;
        padding: 0px 20px;
        background-color: #d81e01;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .feed-tab {
            width: 1170px;
            margin: 0 auto;
            height: 48px;
            display: flex;
            .feed-tab__item {
                position: relative;
                height: 42px;
                margin-top: 6px;
                width: 168px;
                float: left;
                text-align: center;
                cursor: pointer;
                .feed-tab__item-title {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 42px;
                }
                .feed-tab__item-icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
            .feed-tab__item.feed-tab__item--active {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #fff;
                .feed-tab__item-title {
                    color: #d81e01;
                    font-size: 17px;
                }
            }
        }
    }

    .list-wrap {
        width: 1190px;
        overflow: hidden;
        min-height: 500px;
        background-color: #fff;
        padding: 10px 10px 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 30px;
        .goods-info {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 20%;
                margin: 0;
                background: #fff;
                cursor: pointer;
                padding: 0;
                transition: all 0.2s linear;
                .goods-wrap {
                    margin: 0 10px 20px;
                    padding: 15px 0px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    min-height: 225px;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .img-wrap {
                        width: 155px;
                        height: 155px;
                        margin: 0px auto;
                        text-align: center;
                        line-height: 160px;
                        /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                        /*border-radius: 16px;*/
                        overflow: hidden;
                        position: relative;
                        img {
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top: 50%;
                            left: 50%;
                            display: block;
                            transform: translate(-50%, -50%);
                        }
                    }
                    h3 {
                        font-size: 15px;
                        line-height: 24px;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin: 10px 10px 5px;
                    }
                    .desc {
                        margin: 0 10px;
                        height: 14px;
                        font-size: 13px;
                        line-height: 14px;
                        color: #b0b0b0;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .price {
                        margin: 0 10px;
                        font-size: 13px;
                        /*text-align: center;*/
                        font-weight: 700;
                        color: $base-color;
                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }
                }
            }
        }
    }
    .empty {
        margin-top: 45px;
    }
    .pager {
        text-align: center;
        margin: 15px 0;
    }

    .error-wrap {
        text-align: center;
        padding: 50px 0px;
        margin: 100px auto;
        position: relative;
        width: 1210px;
        background-color: #fff;
        height: 300px;
        div {
            margin-bottom: 10px;
        }
        p {
            line-height: 32px;
            font-size: 14px;
            color: #301d1d;
            span {
                line-height: 32px;
                font-size: 14px;
            }
        }
        i {
            font-size: 60px;
        }
        h2 {
            margin: 0 0 30px;
        }
        .go-back {
            margin-right: 10px;
            color: $base-color;
        }
        .go-index {
            color: $base-color;
        }
    }
</style>
